/* ========================================================================
 * Apricot's Static Image, Image Module
 * ======================================================================== */

// javaScript
import Utils from "./CBUtils";

/**
 * Static Image
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {String|Array} data.class
 * @param {String} data.playLabel
 * @param {String} data.pauseLabel
 * @param {Boolean} data.responsive
 * @param {Number} data.timeFrame
 * @returns {{destroy: Function}}
 */

const StaticImage = (inputData = {}) => {
  const defaultData = {
    elem: null,
    markup: true,
    class: null,
    playLabel: "play animated gif",
    pauseLabel: "pause animated gif",
    responsive: false,
    timeFrame: 5,
  };

  let elem = inputData.elem;
  let staticImg = null;
  let animImg = null;
  let btn = null;
  let icon = null;
  let span = null;
  let timerId = null;
  let shouldAnimate = true;

  if (!Utils.elemExists(elem)) return;
  const data = { ...defaultData, ...inputData };

  const isImage = elem.tagName === "IMG" ? true : false;

  const init = async () => {
    elem.staticImagePlugin = "cb";

    if (data.markup) {
      let elemWrapper = elem;
      if (isImage) {
        elemWrapper = document.createElement("DIV");
        Utils.wrap(elem, elemWrapper);
      }

      Utils.addClass(elemWrapper, "cb-static-img");
      data.class && Utils.addClass(elemWrapper, data.class);

      btn = document.createElement("A");
      Utils.attr(btn, "href", "/#");
      Utils.attr(btn, "role", "button");
      Utils.addClass(btn, "cb-static-img-btn");
      Utils.append(elemWrapper, btn);

      span = document.createElement("SPAN");
      Utils.addClass(span, "sr-only");
      span.innerText = data.pauseLabel;

      icon = document.createElement("SPAN");
      Utils.addClass(icon, ["cb-icon", "cb-pause-circle", "cb-icon-32"]);
      Utils.attr(icon, "aria-hidden", "true");

      Utils.append(btn, icon);
      Utils.append(btn, span);
    } else {
      btn = elem.querySelector(".cb-static-img-btn");
      span = elem.querySelector(".sr-only");
      icon = elem.querySelector(".cb-icon");
    }

    if (!Utils.elemExists(btn)) return;
    if (!Utils.elemExists(span)) return;
    if (!Utils.elemExists(icon)) return;

    addEvents();

    setFirstFrame();
    stopAnimation();
  };

  const isGif = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    return extension === "gif";
  };

  const stopAnimation = () => {
    // Clear the previous timer, if there is one
    if (timerId !== null) {
      clearTimeout(timerId);
      timerId = null;
    }

    if (shouldAnimate) {
      timerId = setTimeout(() => {
        toggleIcon();
      }, data.timeFrame * 1000);
    }
  };

  // when image changes due to CBResponsiveImage plugin
  const getBreakpointImg = () => {
    setFirstFrame();
    playIconInteraction();
    stopAnimation();
  };

  const getBackgroundImageSrc = async (element) => {
    let backgroundImage;
    let backgroundImageUrl;

    if (isImage) {
      backgroundImageUrl = element.src;
    } else {
      backgroundImage = window.getComputedStyle(element, null).getPropertyValue("background-image");
      backgroundImageUrl = backgroundImage.match(/\((.*?)\)/)[1].replace(/('|")/g, "");
    }

    return backgroundImageUrl;
  };

  // Function to extract the first frame from the animated GIF
  // and set it as the source of the first frame image
  const setFirstFrame = async () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const firstFrameSrc = canvas.toDataURL("image/png"); // Convert the first frame to a data URL

      staticImg = firstFrameSrc;
      if (Utils.reduceMotionChanged()) {
        setImage(staticImg);
        btn.style.display = "none";
      }
    };

    animImg = await getBackgroundImageSrc(elem);
    if (isGif(animImg)) {
      Utils.removeAttr(btn, "aria-hidden");
      Utils.removeClass(btn, "display-none");
      Utils.attr(elem, "data-cb-static-org", animImg);
      img.src = animImg;
      shouldAnimate = true;
    } else {
      // If no gif hide play/pause and reset
      if (timerId !== null) {
        clearTimeout(timerId);
        timerId = null;
      }
      Utils.attr(btn, "aria-hidden", "true");
      Utils.addClass(btn, "display-none");

      Utils.removeAttr(elem, "data-cb-static-org");
      pauseIconInteraction();
    }
  };

  const pauseIconInteraction = () => {
    Utils.addClass(icon, "cb-play-circle");
    Utils.removeClass(icon, "cb-pause-circle");
    span.innerText = data.playLabel;
  };

  const playIconInteraction = () => {

    shouldAnimate = true;
    Utils.addClass(icon, "cb-pause-circle");
    Utils.removeClass(icon, "cb-play-circle");
    span.innerText = data.pauseLabel;
  };

  const setImage = (src) => {
    if (isImage) {
      elem.src = src;
    } else {
      elem.style.backgroundImage = 'url("' + src + '")';
    }
  };

  const toggleIcon = () => {
    if (Utils.hasClass(icon, "cb-pause-circle")) {
      pauseIconInteraction();

      setImage(staticImg);
      shouldAnimate = false;
      stopAnimation();
    } else {
      playIconInteraction();
      shouldAnimate = true;
      stopAnimation();
      setImage(animImg);
    }
  };

  const addEvents = () => {
    data.responsive && elem.addEventListener("apricot_imageChange", getBreakpointImg);

    btn.addEventListener("click", (e) => {
      e.preventDefault();

      toggleIcon();
    });

    if (btn.tagName === "A") {
      btn.addEventListener("keypress", (e) => {
        if (Utils.whichKey(e) === "ENTER" || Utils.whichKey(e) === "SPACE") {
          e.preventDefault();

          toggleIcon();
        }
      });
    }
  };

  const destroy = () => {
    if (elem.staticImagePlugin === "cb") {
      elem.staticImagePlugin = null;
      data.responsive && elem.removeEventListener("apricot_imageChange", setFirstFrame);
    }
  };

  if (elem.staticImagePlugin !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default StaticImage;
