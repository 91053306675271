/* ========================================================================
 * Apricot's SVG React Illustrations
 * ======================================================================== */

// SCSS
import "../scss/includes/svg-illustrations.scss";


// javaScript
export default {};
