/* ========================================================================
 * Apricot's Notification
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/notification.scss";
import "../scss/includes/button.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Dismissible Notification
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.remove
 * @param {Boolean} data.animation
 * @returns {{destroy: Function}}
 */
const Dismissible = (data = {}) => {
  const defaultData = {
    elem: null,
    remove: false,
    animation: true,
  };
  data = { ...defaultData, ...data };

  let elem = data.elem;
  let close = null;

  if (!Utils.elemExists(elem)) return null;

  const init = () => {
    elem.dismissibleNotification = "cb";
    close = elem.querySelector("[data-cb-notification-close]");
    if (!Utils.elemExists(close)) return null;

    close.addEventListener("click", hideNotification);
    if (useAnimation()) {
      window.addEventListener("resize", adjustNotificationHeight);
    }
  };

  const adjustNotificationHeight = () => {
    Utils.removeAttr(elem, "data-cb-height");
  };

  const hideNotification = (e) => {
    if (e) e.preventDefault();

    const height = elem.offsetHeight;

    elem.style.height = height + "px";
    Utils.attr(elem, "data-cb-height", height);

    setTimeout(() => {
      elem.style.removeProperty("height");
      Utils.addClass(elem, "cb-notification-hide");
      Utils.attr(elem, "aria-hidden", "true");
      const event = new CustomEvent("apricot_notificationHidden");
      elem.dispatchEvent(event);

      if (data.remove) {
        setTimeout(() => {
          Utils.remove(elem);
        }, 60);
      } else {
        // a11y
        Array.from(elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)).forEach((node) => {
          Utils.attr(node, "tabIndex", "-1");
        });
      }
    }, 50);
  };

  const showNotification = () => {
    if (isNaN(Utils.attr(elem, "data-cb-height"))) {
      elem.style.height = "auto";
    } else {
      elem.style.height = Utils.attr(elem, "data-cb-height") + "px";
    }

    setTimeout(() => {
      Utils.removeClass(elem, "cb-notification-hide");
      Utils.removeAttr(elem, "aria-hidden");
      elem.style.removeProperty("height");

      const event = new CustomEvent("apricot_notificationShown");
      elem.dispatchEvent(event);
      // a11y
      Array.from(elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS)).forEach((node) => {
        Utils.attr(node, "tabIndex", "0");
      });
    }, 50);
  };

  const useAnimation = () => {
    return Utils.reduceMotionChanged() ? false : data.animation;
  };

  const destroy = () => {
    if (elem.dismissibleNotification === "cb") {
      elem.dismissibleNotification = null;
      close.removeEventListener("click", hideNotification);

      if (useAnimation()) {
        window.removeEventListener("resize", adjustNotificationHeight);
      }
    }
  };

  if (elem.dismissibleNotification !== "cb") {
    init();
  }

  return {
    hideNotification: hideNotification,
    showNotification: showNotification,
    destroy: destroy,
  };
};

const CBNotification = {
  Dismissible,
};

if (typeof window !== "undefined") {
  window.cb = window.cb || {};
  window.cb.apricot = window.cb.apricot || {};
  window.cb.apricot.CBNotification = CBNotification;
}

export default CBNotification;